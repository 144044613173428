import {
  Component,
  ElementRef,
  Inject,
  Renderer2,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../shared/services/config.service';
import { RouterModule, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class ContentLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('content-wrapper') wrapper: ElementRef;
  config: any = {};
  layoutSub: Subscription;

  constructor(
    protected configService: ConfigService,
    protected renderer: Renderer2,
    protected cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) protected document: Document,
  ) {
    this.config = this.configService.templateConf;
    this.renderer.addClass(this.document.body, 'auth-page');
  }

  ngOnInit() {
    this.layoutSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();
    });
  }

  loadLayout() {
    if (this.config.layout.variant === 'Light') {
      this.renderer.removeClass(this.document.body, 'dark');
      this.renderer.removeClass(this.document.body, 'layout-transparent');
    } else if (this.config.layout.variant === 'Dark') {
      this.renderer.removeClass(this.document.body, 'layout-transparent');
      this.renderer.addClass(this.document.body, 'dark');
    } else if (this.config.layout.variant === 'Transparent') {
      this.renderer.addClass(this.document.body, 'dark');
      this.renderer.addClass(this.document.body, 'layout-transparent');
      this.renderer.addClass(this.document.body, this.config.layout.sidebar.backgroundColor);
    }

    this.renderer.removeClass(this.document.body, 'menu-expanded');
    this.renderer.removeClass(this.document.body, 'navbar-static');
    this.renderer.removeClass(this.document.body, 'menu-open');
    this.renderer.addClass(this.document.body, 'blank-page');
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'auth-page');
    this.layoutSub?.unsubscribe();
  }

  onRouteActivate(event) {
    // TODO: header update
  }
}
