import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';

import { FULL_ROUTES } from './layouts/full/full-layout.routes';
import { CONTENT_ROUTES } from './layouts/content/content-layout.routes';
import { TenantsResolver } from './shared/resolvers/tenants.resolver';

import { environment } from '../environments/environment';
import { VideoCallResolver } from './shared/resolvers/video-call.resolver';
import { VideoCallModalComponent } from './modals/video-call/video-call-modal.component';
import { NoInternetPageComponent } from './pages/no-internet-page/no-internet-page.component';
import { AuthGuard } from './shared/guards/auth-guard.service';
import { roleGuardFactory } from './shared/guards/role.guard';

/** Depends on env settings, use subdomains */
const appRoutes: Routes = environment.subdomains
  ? [
      {
        path: '',
        redirectTo: environment.subdomains ? '/auth/login' : '/root/auth/login',
        pathMatch: 'full',
      },
      {
        path: 'meet/:id',
        component: VideoCallModalComponent,
        resolve: [VideoCallResolver],
      },
      {
        path: '',
        resolve: { tenant: TenantsResolver },
        children: [
          {
            path: '',
            component: ContentLayoutComponent,
            data: { title: 'content Views' },
            children: CONTENT_ROUTES,
          },
          {
            path: '',
            component: FullLayoutComponent,
            data: { title: 'full Views' },
            children: FULL_ROUTES,
          },
          {
            path: 'settings',
            loadComponent: () =>
              import('./pages/settings/dashboard/dashboard.component').then(
                (m) => m.SettingsDashboardComponent,
              ),
            canActivate: [AuthGuard],
            children: [
              { path: '', redirectTo: 'home', pathMatch: 'full' },
              {
                path: 'home',
                loadComponent: () =>
                  import('./pages/settings/home/home.component').then((m) => m.HomeComponent),
                data: { animation: 'HomePage' },
              },
              {
                path: 'users',
                loadComponent: () =>
                  import('./pages/settings/users/users.component').then((m) => m.UsersComponent),
                data: { animation: 'UsersPage' },
                canActivate: [
                  roleGuardFactory({
                    type: 'union',
                    roles: [
                      { object: 'tenants', roleName: 'Admin' },
                      { object: 'tenants', roleName: 'Owner' },
                    ],
                  }),
                ],
              },
              {
                path: 'invitations',
                loadComponent: () =>
                  import('./pages/settings/tenant-invitations/tenant-invitations.component').then(
                    (m) => m.TenantInvitationsComponent,
                  ),
                data: { animation: 'TenantInvitationsPage' },
                canActivate: [
                  roleGuardFactory({
                    type: 'union',
                    roles: [
                      { object: 'tenants', roleName: 'Admin' },
                      { object: 'tenants', roleName: 'Owner' },
                    ],
                  }),
                ],
              },
              {
                path: 'profile',
                loadComponent: () =>
                  import('./pages/settings/user-edit/user-edit.component').then(
                    (m) => m.UserEditComponent,
                  ),
                data: { animation: 'UserEditPage' },
              },
            ],
          },
          {
            path: environment.subdomains ? 'connection-lost' : 'root/connection-lost',
            component: NoInternetPageComponent,
          },
          {
            path: '**',
            redirectTo: environment.subdomains ? '/error404' : '/root/error404',
          },
        ],
      },
    ]
  : [
      {
        path: '',
        redirectTo: 'root',
        pathMatch: 'full',
      },
      {
        path: 'root',
        children: [
          {
            path: '',
            redirectTo: environment.subdomains ? '/auth/login' : '/root/auth/login',
            pathMatch: 'full',
          },
          {
            path: '',
            component: ContentLayoutComponent,
            data: { title: 'content Views' },
            children: CONTENT_ROUTES,
          },
        ],
      },
      {
        path: 'meet/:id',
        component: VideoCallModalComponent,
        resolve: [VideoCallResolver],
      },
      {
        path: ':tenant',
        resolve: { tenant: TenantsResolver },
        children: [
          {
            path: 'settings',
            loadComponent: () =>
              import('./pages/settings/dashboard/dashboard.component').then(
                (m) => m.SettingsDashboardComponent,
              ),
            canActivate: [AuthGuard],
            children: [
              { path: '', redirectTo: 'home', pathMatch: 'full' },
              {
                path: 'home',
                loadComponent: () =>
                  import('./pages/settings/home/home.component').then((m) => m.HomeComponent),
                data: { animation: 'HomePage' },
              },
              {
                path: 'users',
                loadComponent: () =>
                  import('./pages/settings/users/users.component').then((m) => m.UsersComponent),
                data: { animation: 'UsersPage' },
                canActivate: [
                  roleGuardFactory({
                    type: 'union',
                    roles: [
                      { object: 'tenants', roleName: 'Admin' },
                      { object: 'tenants', roleName: 'Owner' },
                    ],
                  }),
                ],
              },
              {
                path: 'invitations',
                loadComponent: () =>
                  import('./pages/settings/tenant-invitations/tenant-invitations.component').then(
                    (m) => m.TenantInvitationsComponent,
                  ),
                data: { animation: 'TenantInvitationsPage' },
                canActivate: [
                  roleGuardFactory({
                    type: 'union',
                    roles: [
                      { object: 'tenants', roleName: 'Admin' },
                      { object: 'tenants', roleName: 'Owner' },
                    ],
                  }),
                ],
              },
              {
                path: 'profile',
                loadComponent: () =>
                  import('./pages/settings/user-edit/user-edit.component').then(
                    (m) => m.UserEditComponent,
                  ),
                data: { animation: 'UserEditPage' },
              },
            ],
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'auth/login',
          },
          {
            path: '',
            component: ContentLayoutComponent,
            data: { title: 'content Views' },
            children: CONTENT_ROUTES,
          },
          {
            path: '',
            component: FullLayoutComponent,
            data: { title: 'full Views' },
            children: FULL_ROUTES,
          },
        ],
      },
      {
        path: environment.subdomains ? 'connection-lost' : 'root/connection-lost',
        component: NoInternetPageComponent,
      },

      {
        path: '**',
        redirectTo: environment.subdomains ? '/error404' : '/root/error404',
      },
    ];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
  providers: [TenantsResolver, VideoCallResolver],
})
export class AppRoutingModule {}
