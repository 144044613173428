<ng-container *transloco="let t">
  <app-listeners></app-listeners>
  <!-- *NAVBAR* starts -->
  <app-navbar [showSidebar]="showSidebar"></app-navbar>
  <!-- *NAVBAR* ends -->

  <div class="wrapper d-flex">
    <div class="w-100">
      <!-- *SIDE MENU* starts -->
      <div appSidebar class="app-sidebar">
        <div class="card m-0">
          <div class="chat-sidebar">
            <app-chat-navigation></app-chat-navigation>
          </div>
        </div>
      </div>
      <!-- *SIDE MENU* ends -->

      <div class="main-panel">
        <div class="main-content" [class.no-sidebar]="!showSidebar">
          <div class="content-overlay"></div>
          <div class="content-wrapper">
            <div class="d-flex router-wrap">
              <div class="w-100">
                <router-outlet (activate)="onRouteActivate($event)"></router-outlet>
              </div>
            </div>
          </div>
        </div>

        <!-- FOOTER starts -->
        <app-footer></app-footer>
        <!-- FOOTER ends -->
      </div>

      <div class="drag-target"></div>
    </div>
  </div>

  <app-drafts></app-drafts>

  <!-- *RIGHT SIDEBAR* starts -->
  <div *ngIf="sidebarConfig?.isOpened" [class.note-sidebar]="isNoteBar" class="right-sidebar">
    <app-notifications
      *ngIf="sidebarConfig?.sidebarContentType === 'notifications-new'"
      object="users"
    ></app-notifications>
    <!-- <app-action-logs *ngIf="sidebarConfig?.sidebarContentType === 'notifications'" object="users"></app-action-logs> -->
    <app-notes *ngIf="isNoteBar && ('users::null::notesGet' | checkPermission)"></app-notes>
  </div>
  <!-- *RIGHT SIDEBAR* ends -->

  <div
    t-id="full-layout-show-notes"
    class="add-fab-button show-notes-fab-button"
    [class.sidebar-opened]="sidebarConfig?.isOpened"
    [class.fab-hide]="
      sidebarConfig?.isOpened && sidebarConfig?.sidebarContentType === 'notifications-new'
    "
    (click)="toggleSidebar()"
  >
    <app-svg name="pen" width="14" height="14"></app-svg>
  </div>

  <emoji-mart
    *ngIf="projectEmojiIsOpen"
    class="emoji-picker-project"
    [title]="t('full-layout.emoji-picker-project')"
    emoji=""
    [backgroundImageFn]="emojiPickerImageFn"
    [autoFocus]="true"
    [isNative]="true"
    [darkMode]="darkMode"
    [exclude]="['flags']"
    [recent]="customRecentEmojis"
    (emojiSelect)="addProjectEmoji($event)"
  ></emoji-mart>

  <div class="tooltip-wrapper" *ngIf="tooltipTitle" [ngStyle]="tooltipStyle">
    <div class="ticket-title-tooltip">
      <span class="tooltip-title">
        {{ tooltipTitle }}
      </span>
    </div>
    <div class="ticket-title-tooltip-arrow-down" [ngStyle]="tooltipArrowStyle"></div>
  </div>
</ng-container>
