<!--Error page starts-->
<section id="error" *transloco="let t; read: 'error404'">
  <div class="container-fluid">
    <div class="row auth-body full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="row">
          <div class="col-12 text-center">
            <img
              src="assets/img/gallery/error.png"
              alt=""
              class="img-fluid error-img mt-2"
              height="300"
              width="400"
            />
            <h1 class="mt-4">{{ t('page-not-found') }}</h1>
            <div class="w-75 error-text mx-auto mt-4">
              <p>{{ t('description') }}</p>
            </div>
            <a [routerLink]="['/']" class="btn btn-warning my-2">{{ t('btn-back') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Error page ends-->
