import { Component, Inject, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Subscription, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

import { environment } from '../environments/environment';
import { OnlineStatusService } from './shared/services/online-status.service';
import { DocumentsToggleImageGallery } from './shared/store/actions/documents.action';
import { TenantsState } from './shared/store/states/tenants.state';
import { SocketsService } from './shared/services/sockets.service';
import { AuthState } from './shared/store/states/auth.state';
import { TauriService } from './shared/services/tauri.service';
import { PlatformService } from './shared/services/platform.service';
import { AppDatabase } from './standalone/helpers/app.database';
import { MixpanelService } from './plugins/mixpanel/mixpanel.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private actions: Actions,
    private onlineService: OnlineStatusService,
    private deviceService: DeviceDetectorService,
    readonly store: Store,
    protected socketsService: SocketsService,
    private tauriService: TauriService,
    private platformService: PlatformService,

    private adb: AppDatabase,
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent) {
    MixpanelService.trackEvent('Session end');
  }

  ngOnInit() {
    MixpanelService.trackEvent('Session start');

    this.loadScript('https://8x8.vc/external_api.js');
    this.loadScript('https://www.webrtc-experiment.com/EBML.js');

    MixpanelService.setSuperProperties({ platform: this.platformService.getSystemInfo() });
    this.tauriService.checkUpdate();
    if (environment?.name === 'dev' || environment?.name === 'stage') {
      this.loadNoIndexMetaTag();
    }

    const hostParts = window.location.hostname.split('.');

    if (this.deviceService.isMobile() && hostParts[0] !== 'mobile') {
      this.navigateForMobile(hostParts);
    }

    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));

    this.actions
      .pipe(takeUntil(this.destroy$), ofActionDispatched(DocumentsToggleImageGallery))
      .subscribe(
        ({ payload }) => (this.document.body.style.overflow = payload ? 'hidden' : 'auto'),
      );

    this.onlineService.setNetworkListener();
  }

  ngOnDestroy() {
    const tenants = this.store.selectSnapshot(AuthState.getTenantList);
    const currentTenant = tenants.find(
      (tenant) => tenant.name === this.store.selectSnapshot(TenantsState.getTenantName),
    );

    tenants.forEach((tenant) => {
      if (currentTenant.name !== tenant.name) {
        this.socketsService.get().emit('tenants.unsubscribe.updates', { userId: tenant.userId });
      }
    });

    this.subscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadScript(url: string): void {
    const script = document.createElement('script');
    script.src = url;
    script.async = false;
    script.defer = true;
    document.body.appendChild(script);
  }

  loadNoIndexMetaTag() {
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex';
    document.head.appendChild(meta);
  }

  navigateForMobile(hostParts): void {
    // const urlParts = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(window.location.href);
    const host = environment.main_host || environment.base_host;
    let currentRoute = window.location.pathname + window.location.search;
    const tenant =
      hostParts?.length > 2 && !['app', 'api', 'mobile'].includes(hostParts[0]) ? hostParts[0] : '';
    const segments = currentRoute.split(/[\/?]/);

    // ------ START: For kanban board urls -------
    if (segments.some((segment) => segment === 'board')) {
      const subdomainOffset = environment.subdomains ? 3 : 4;
      const pathSegment = segments.slice(0, subdomainOffset);
      const typeOfBoard = segments[subdomainOffset + 1];
      const ticketQuery = segments[subdomainOffset + 2];

      currentRoute = `${pathSegment.join('/')}?tab=tabBoard&subTab=${typeOfBoard}${
        ticketQuery ? '&' + ticketQuery : ''
      }`;
    }
    // ------ END: For kanban board urls -------

    window.location.href =
      hostParts[0] !== 'localhost'
        ? `http${environment.ssl ? 's' : ''}://mobile.${host}/${tenant}${currentRoute}`
        : `http://localhost:4300${currentRoute}`;
  }
}
