import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';

import { AuthState } from '../store/states/auth.state';

export const roleGuardFactory = (config: {
  type: 'union' | 'intersection';
  roles: { object: string; roleName: string }[];
}): CanActivateFn => {
  return (route: ActivatedRouteSnapshot) => {
    const store = inject(Store);
    const router = inject(Router);

    const userRoles: { object: string; roleName: string }[] =
      store.selectSnapshot(AuthState.getUser)?.roles ?? ([] as any);

    const mappedConfigRoles = config.roles.map((role) => `${role.object}:${role.roleName}`);

    const canActivate =
      config.roles.length === 0
        ? true
        : config.type === 'union'
          ? userRoles.some((role) => mappedConfigRoles.includes(`${role.object}:${role.roleName}`))
          : userRoles.every((role) =>
              mappedConfigRoles.includes(`${role.object}:${role.roleName}`),
            );

    if (!canActivate) {
      router.navigateByUrl('/');
    }

    return canActivate;
  };
};
