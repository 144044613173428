import { Store, State, Action, StateContext } from '@ngxs/store';

import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { EmojisService } from '../../../api/services/emojis.service';

import {
  EmojiCreate,
  EmojiDelete,
  SocketNewEmoji,
  SocketDeleteEmoji,
} from '../actions/emojis.action';

import { ChatsAddEmojiReaction, ChatsDeleteEmojiReaction } from '../actions/chats.action';

@State<any>({
  name: 'Emojis',
})
@Injectable()
export class EmojisState {
  constructor(
    private emojisService: EmojisService,
    private store: Store,
    private toastr: ToastrService,
  ) {}

  /**

* Create emoji action handler

* @param {getState, setState, patchState}: StateContext<any>

* @param {EmojiCreate} action

*/

  @Action(EmojiCreate)
  create_emoji(
    { getState, setState, patchState }: StateContext<any>,
    { payload: { body } }: EmojiCreate,
  ) {
    return this.emojisService.emojisCreate({ body }).pipe(
      tap(
        (result) => {},

        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**

* Delete emoji action handler

* @param {getState, setState, patchState}: StateContext<any>

* @param {EmojiCreate} action

*/

  @Action(EmojiDelete)
  delete_emoji(
    { getState, setState, patchState }: StateContext<any>,
    { payload: { emojiId } }: EmojiDelete,
  ) {
    return this.emojisService.emojisDelete({ id: emojiId }).pipe(
      tap(
        (result) => {},

        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**

* Socket new emoji action handler

* @param {getState, setState, patchState}: StateContext<any>

* @param {SocketNewEmoji} action

*/

  @Action(SocketNewEmoji)
  socket_new_emoji(
    { getState, setState, patchState }: StateContext<any>,
    { payload: emoji }: SocketNewEmoji,
  ) {
    switch (emoji.messageObject) {
      case 'chat-messages':
        this.store.dispatch(new ChatsAddEmojiReaction({ chatId: emoji.chatId, emoji }));

        break;
    }
  }

  /**

* Socket delete emoji action handler

* @param {getState, setState, patchState}: StateContext<any>

* @param {SocketDeleteEmoji} action

*/

  @Action(SocketDeleteEmoji)
  socket_delete_emoji(
    { getState, setState, patchState }: StateContext<any>,
    { payload: emoji }: SocketDeleteEmoji,
  ) {
    switch (emoji.messageObject) {
      case 'chat-messages':
        this.store.dispatch(
          new ChatsDeleteEmojiReaction({
            chatId: emoji.chatId,
            messageId: emoji.messageObjectId,
            emojiId: emoji._id,
          }),
        );

        break;
    }
  }
}
