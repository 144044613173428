import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { LaneBoardStateModel } from '../models/LaneBoardState';
import { LaneBoardsService } from '../../../api/services/lane-boards.service';
import { LaneBoardGetList } from '../actions/lane-board-list.action';

@State<Array<LaneBoardStateModel>>({
  name: 'LaneBoardList',
  defaults: [],
})
@Injectable()
export class LaneBoardListState {
  /**
   * Get notes-board
   * @param  {LaneBoardStateModel} state
   */
  @Selector()
  static getLaneBoardList(state: Array<LaneBoardStateModel>) {
    return state;
  }

  constructor(private laneBoardsService: LaneBoardsService) {}

  /**
   * Get list notes-board request
   * @param  {setState}: StateContext<Array<LaneBoardStateModel>>
   * @param  {LaneBoardGetList} action
   */
  @Action(LaneBoardGetList)
  get_lane_board_list(
    { setState, getState }: StateContext<Array<LaneBoardStateModel>>,
    action: LaneBoardGetList,
  ) {
    return this.laneBoardsService.laneBoardsControllerLaneBoardsList({ ...action.payload }).pipe(
      tap((laneBoards) => {
        setState(laneBoards as unknown as Array<LaneBoardStateModel>);
      }),
    );
  }
}
