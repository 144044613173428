import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { HealthService } from '../../api/services/health.service';
import { OnlineStatusService } from '../../shared/services/online-status.service';
import { TranslocoDirective } from '@ngneat/transloco';
import { TrackPageViews } from '../../plugins/mixpanel/mixpanel.decorator';
@TrackPageViews('No Internet')
@Component({
  selector: 'app-no-internet-page',
  templateUrl: './no-internet-page.component.html',
  styleUrls: ['./no-internet-page.component.scss'],
  standalone: true,
  imports: [TranslocoDirective],
})
export class NoInternetPageComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();

  constructor(
    public onlineStatus: OnlineStatusService,
    private healthService: HealthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.healthService
      .getHealth()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.onlineStatus.setStatus(true);
          this.router.navigate(['']);
        },
        (error) => catchError(error),
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
