import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TrackPageViews } from '../../plugins/mixpanel/mixpanel.decorator';

@TrackPageViews('404 Page')
@Component({
  selector: 'app-error404-page',
  templateUrl: './error404-page.component.html',
  styleUrls: ['./error404-page.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, RouterLink],
})
export class Error404PageComponent {}
