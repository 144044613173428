import { Action, State, Store, Selector, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { AvatarStateModel } from '../models/AvatarState';
import {
  SpaceAvatarGet,
  SpaceAvatarSet,
  ProjectAvatarGet,
  ProjectAvatarSet,
  TenantAvatarGet,
  TenantAvatarSet,
  UserAvatarGet,
  UserAvatarSet,
  AvatarDelete,
  WebhookAvatarSet,
  WebhookAvatarGet,
} from '../actions/avatar.action';
import { FilesService } from '../../../api/services/files.service';
import { UpdateUserAvatar } from '../actions/users.action';
import { SpaceUpdateAvatar } from '../actions/spaces.action';
import { ProjectUpdateAvatar } from '../actions/projects.action';
import { SpaceSetAvatarImageUploadLoading } from '../actions/spaces.action';
import { UserSetAvatarImageUploadLoading } from '../actions/users.action';
import { ProjectSetAvatarImageUploadLoading } from '../actions/projects.action';

@State<AvatarStateModel>({
  name: 'Avatar',
  defaults: {
    userAvatars: {},
    spaceAvatars: {},
    projectAvatars: {},
    tenantAvatars: {},
    webhookAvatars: {},
  },
})
@Injectable()
export class AvatarsState {
  @Selector()
  static getUserAvatar(state: AvatarStateModel) {
    return (userId: string) => state.userAvatars[userId];
  }

  @Selector()
  static getSpaceAvatar(state: AvatarStateModel) {
    return (spaceId: string) => state.spaceAvatars[spaceId];
  }

  @Selector()
  static getProjectAvatar(state: AvatarStateModel) {
    return (projectId: string) => state.projectAvatars[projectId];
  }

  @Selector()
  static getTenantAvatar(state: AvatarStateModel) {
    return (tenantId: string) => state.tenantAvatars[tenantId];
  }

  @Selector()
  static getWebhookAvatar(state: AvatarStateModel) {
    return (webhookId: string) => state.webhookAvatars[webhookId];
  }

  constructor(
    private filesService: FilesService,
    private store: Store,
  ) {}

  /**
   * Get avatars action handler
   * @param  {patchState}: StateContext<AvatarStateModel>
   * @param  {UserAvatarGet} action
   */
  @Action(UserAvatarGet)
  userAvatarGet({ getState, patchState }: StateContext<AvatarStateModel>, action: UserAvatarGet) {
    return this.filesService
      .contentsGet({
        type: 'avatar',
        object: 'users',
        objectId: action.payload.id,
      })
      .pipe(
        tap(
          (result) => {
            if (result) {
              const userAvatars = { ...getState().userAvatars };
              userAvatars[action.payload.id] = result;
              patchState({ userAvatars });
            }
          },
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  @Action(SpaceAvatarGet)
  spaceAvatarGet({ getState, patchState }: StateContext<AvatarStateModel>, action: SpaceAvatarGet) {
    return this.filesService
      .contentsGet({
        type: 'avatar',
        object: 'spaces',
        objectId: action.payload.id,
      })
      .pipe(
        tap(
          (result) => {
            if (result) {
              const spaceAvatars = { ...getState().spaceAvatars };
              spaceAvatars[action.payload.id] = result;
              patchState({ spaceAvatars });
            }
          },
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  @Action(ProjectAvatarGet)
  projectAvatarGet(
    { getState, patchState }: StateContext<AvatarStateModel>,
    action: ProjectAvatarGet,
  ) {
    return this.filesService
      .contentsGet({
        type: 'avatar',
        object: 'projects',
        objectId: action.payload.id,
      })
      .pipe(
        tap(
          (result) => {
            if (result) {
              const projectAvatars = { ...getState().projectAvatars };
              projectAvatars[action.payload.id] = result;
              patchState({ projectAvatars });
            }
          },
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  @Action(TenantAvatarGet)
  tenantAvatarGet(
    { getState, patchState }: StateContext<AvatarStateModel>,
    action: TenantAvatarGet,
  ) {
    return this.filesService
      .contentsGet({
        type: 'avatar',
        object: 'tenants',
        objectId: action.payload.id,
      })
      .pipe(
        tap(
          (result) => {
            if (result) {
              const tenantAvatars = { ...getState().tenantAvatars };
              tenantAvatars[action.payload.id] = result;
              patchState({ tenantAvatars });
            }
          },
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  @Action(WebhookAvatarGet)
  webhookAvatarGet(
    { getState, patchState }: StateContext<AvatarStateModel>,
    action: ProjectAvatarGet,
  ) {
    return this.filesService
      .contentsGet({
        type: 'avatar',
        object: 'webhooks',
        objectId: action.payload.id,
      })
      .pipe(
        tap(
          (result) => {
            if (result) {
              const webhookAvatars = { ...getState().webhookAvatars };
              webhookAvatars[action.payload.id] = result;
              patchState({ webhookAvatars });
            }
          },
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  @Action(UserAvatarSet)
  userAvatarSet({ getState, patchState }: StateContext<AvatarStateModel>, action: UserAvatarSet) {
    this.store.dispatch(
      new UserSetAvatarImageUploadLoading({ userId: action.payload.id, status: true }),
    );
    return this.filesService
      .contentsUpload({
        type: 'avatar',
        object: 'users',
        objectId: action.payload.id,
        body: {
          file: action.payload.file,
        },
      })
      .pipe(
        tap(
          (result) => {
            if (result) {
              this.store.dispatch(
                new UpdateUserAvatar({ userId: action.payload.id, avatarUrl: result.url }),
              );
              const userAvatars = { ...getState().userAvatars };
              userAvatars[action.payload.id] = result;
              patchState({ userAvatars });
              this.store.dispatch(
                new UserSetAvatarImageUploadLoading({ userId: action.payload.id, status: false }),
              );
            }
          },
          (err) => {
            this.store.dispatch(
              new UserSetAvatarImageUploadLoading({ userId: action.payload.id, status: false }),
            );
            throw err.error;
          },
        ),
      );
  }

  @Action(SpaceAvatarSet)
  spaceAvatarSet({ getState, patchState }: StateContext<AvatarStateModel>, action: SpaceAvatarSet) {
    this.store.dispatch(
      new SpaceSetAvatarImageUploadLoading({ id: action.payload.id, status: true }),
    );

    return this.filesService
      .contentsUpload({
        type: 'avatar',
        object: 'spaces',
        objectId: action.payload.id,
        body: {
          file: action.payload.file,
        },
      })
      .pipe(
        tap(
          (result) => {
            if (result) {
              this.store.dispatch(
                new SpaceUpdateAvatar({ spaceId: action.payload.id, avatarUrl: result.url }),
              );
              const spaceAvatars = { ...getState().spaceAvatars };
              spaceAvatars[action.payload.id] = result;
              patchState({ spaceAvatars });
              this.store.dispatch(
                new SpaceSetAvatarImageUploadLoading({ id: action.payload.id, status: false }),
              );
            }
          },
          (err) => {
            this.store.dispatch(
              new SpaceSetAvatarImageUploadLoading({ id: action.payload.id, status: false }),
            );
            throw err.error;
          },
        ),
      );
  }

  @Action(ProjectAvatarSet)
  projectAvatarSet(
    { getState, patchState }: StateContext<AvatarStateModel>,
    action: ProjectAvatarSet,
  ) {
    this.store.dispatch(
      new ProjectSetAvatarImageUploadLoading({ id: action.payload.id, status: true }),
    );
    return this.filesService
      .contentsUpload({
        type: 'avatar',
        object: 'projects',
        objectId: action.payload.id,
        body: {
          file: action.payload.file,
        },
      })
      .pipe(
        tap(
          (result) => {
            if (result) {
              this.store.dispatch(
                new ProjectUpdateAvatar({ projectId: action.payload.id, avatarUrl: result.url }),
              );
              const projectAvatars = { ...getState().projectAvatars };
              projectAvatars[action.payload.id] = result;
              patchState({ projectAvatars });
              this.store.dispatch(
                new ProjectSetAvatarImageUploadLoading({ id: action.payload.id, status: false }),
              );
            }
          },
          (err) => {
            this.store.dispatch(
              new ProjectSetAvatarImageUploadLoading({ id: action.payload.id, status: false }),
            );
            throw err.error;
          },
        ),
      );
  }

  @Action(TenantAvatarSet)
  tenantAvatarSet(
    { getState, patchState }: StateContext<AvatarStateModel>,
    action: TenantAvatarSet,
  ) {
    return this.filesService
      .contentsUpload({
        type: 'avatar',
        object: 'tenants',
        objectId: action.payload.id,
        body: {
          file: action.payload.file,
        },
      })
      .pipe(
        tap(
          (result) => {
            if (result) {
              const tenantAvatars = { ...getState().tenantAvatars };
              tenantAvatars[action.payload.id] = result;
              patchState({ tenantAvatars });
            }
          },
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  /**
   * Avatar Delete events action handler
   * @param  {AvatarDelete} action
   */
  @Action(AvatarDelete)
  avatar_delete({}: StateContext<AvatarStateModel>, action: AvatarDelete) {
    return this.filesService.contentsDelete(action.payload).pipe(
      tap(
        (result) => {},
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(WebhookAvatarSet)
  webhookAvatarSet(
    { getState, patchState }: StateContext<AvatarStateModel>,
    action: WebhookAvatarSet,
  ) {
    return this.filesService
      .contentsUpload({
        type: 'avatar',
        object: 'webhooks',
        objectId: action.payload.id,
        body: {
          file: action.payload.file,
        },
      })
      .pipe(
        tap(
          (result) => {
            if (result) {
              const webhookAvatars = { ...getState().webhookAvatars };
              webhookAvatars[action.payload.id] = result;
              patchState({ webhookAvatars });
            }
          },
          (err) => {
            throw err.error;
          },
        ),
      );
  }
}
