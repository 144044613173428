import { Route, Routes } from '@angular/router';

import { Error404PageComponent } from '../../pages/error404-page/error404-page.component';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...
export const CONTENT_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../../pages/auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
  },
  {
    path: 'error404',
    component: Error404PageComponent,
    data: {
      title: 'Error Page',
    },
  },
  {
    path: 'two-factor-authentication',
    loadChildren: () =>
      import(
        '../../pages/auth-pages/two-factor-authentication/two-factor-authentication.module'
      ).then((m) => m.TwoFactorAuthenticationModule),
  },
];
