import {
  enableProdMode,
  Provider,
  forwardRef,
  ErrorHandler,
  APP_INITIALIZER,
  importProvidersFrom,
} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { API_INTERCEPTOR_PROVIDER } from './app/app.module';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { TranslocoRootModule } from './app/transloco-root.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MentionModule } from 'angular-mentions';
import { LinkyModule } from 'ngx-linky';
import { QuillConfig } from './app/shared/data/quill-configuration';
import { QuillModule } from 'ngx-quill';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SocketIoModule } from 'ngx-socket-io';
import { ApiModule } from './app/api/api.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxsStoreModule } from './app/shared/store/store.module';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { SharedModule } from './app/shared/shared.module';
import { AppRoutingModule } from './app/app-routing.module';
import { ArchwizardModule } from '@achimha/angular-archwizard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { PaymentService } from './app/modals/payment-modal/payment.service';
import { TicketService } from './app/shared/services/ticket.service';
import { CustomFieldService } from './app/modals/board-ticket/custom-fields/services/custom-field.service';
import { DragNDropService } from './app/shared/services/drag-n-drop.service';
import { FilesHelper } from './app/shared/utils/files-helper';
import { SocketsService } from './app/shared/services/sockets.service';
import { RouterQueryService } from './app/shared/services/router-query.service';
import { RedirectService } from './app/shared/services/redirect.service';
import { Router } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NonAuthGuard } from './app/shared/guards/non-auth-guard.service';
import { AppDatabase } from './app/standalone/helpers/app.database';
import { AuthGuard } from './app/shared/guards/auth-guard.service';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { ApiInterceptor } from './app/shared/interceptors/api.interceptor';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';
import { MixpanelService } from './app/plugins/mixpanel/mixpanel.service';

if (environment.production) {
  enableProdMode();
}

export function initializeMixpanel() {
  return (): Promise<any> => {
    return new Promise((resolve) => {
      MixpanelService.getInstance();
      resolve(true);
    });
  };
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      CommonModule,
      NgSelectModule,
      FormsModule,
      ArchwizardModule,
      AppRoutingModule,
      SharedModule,
      ReactiveFormsModule,
      NgxMaskDirective,
      NgxMaskPipe,
      PinchZoomModule,
      NgbModule,
      // PerfectScrollbarModule,
      NgxsStoreModule,
      ToastrModule.forRoot({
        preventDuplicates: true,
      }),
      ApiModule.forRoot({ rootUrl: environment.api_root }),
      SocketIoModule.forRoot({
        url: environment.socket_url,
        options: {
          autoConnect: false,
          reconnection: true,
          reconnectionDelay: 5000,
        },
      }),
      PickerModule,
      NgxsReduxDevtoolsPluginModule.forRoot(),
      QuillModule.forRoot(QuillConfig),
      LinkyModule,
      // HTMLEscapeUnescapeModule,
      MentionModule,
      NgScrollbarModule,
      DragDropModule,
      PdfViewerModule,
      NgxDocViewerModule,
      AngularSvgIconModule.forRoot(),
      TranslocoRootModule,
      TranslocoDirective,
      NgOptimizedImage,
    ),
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    AuthGuard,
    AppDatabase,
    NonAuthGuard,
    NgbActiveModal,
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeMixpanel,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    RedirectService,
    RouterQueryService,
    SocketsService,
    FilesHelper,
    DragNDropService,
    CustomFieldService,
    TicketService,
    PaymentService,
    provideNgxMask(),
    provideAnimations(),
    provideHttpClient(withFetch()),
  ],
});
