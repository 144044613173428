import { ChangeDetectorRef, Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { Subject, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';

import { OpenVideoCallMobile } from '../../shared/store/actions/modals.action';
import {
  NotificationsSetPlayRingtone,
  PushNotificationsSettingsCreate,
} from '../../shared/store/actions/notifications.action';
import {
  VideoCallSetLastSessionId,
  VideoCallsSendAction,
} from '../../shared/store/actions/video-calls.action';
import { UsersState } from '../../shared/store/states/users.state';
import { ChatsState } from '../../shared/store/states/chats.state';
import { SpacesState } from '../../shared/store/states/spaces.state';
import { ProjectsState } from '../../shared/store/states/projects.state';
import { VideoCallModalComponent } from '../video-call/video-call-modal.component';
import { AuthState } from '../../shared/store/states/auth.state';
import { ChatsGet } from '../../shared/store/actions/chats.action';
import { ProjectAvatarComponent } from '../../shared/components/space-projects/project-avatar/project-avatar.component';
import { SpaceAvatarComponent } from '../../shared/components/space-projects/space-avatar/space-avatar.component';
import { AvatarComponent } from '../../standalone/components/avatar/avatar.component';
import { NgFor, NgIf } from '@angular/common';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';

@Component({
  selector: 'app-incoming-call-modal',
  templateUrl: './incoming-call-modal.component.html',
  styleUrls: ['./incoming-call-modal.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    SvgComponent,
    NgFor,
    NgIf,
    forwardRef(() => AvatarComponent),
    SpaceAvatarComponent,
    ProjectAvatarComponent,
  ],
})
export class IncomingCallModalComponent implements OnInit, OnDestroy {
  MAX_LINED_UP_PARTICIPANTS = 3;
  destroy$: Subject<void> = new Subject<void>();

  platform = 'web';
  room: any;
  chat: any;
  user: any;
  participants: any[];
  object: string;
  objectId: string;
  space: any;
  project: any;
  isUpdating = false;
  noRingtone = false;

  constructor(
    private actions: Actions,
    private store: Store,
    private modalsService: NgbModal,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService,
    private activeModal: NgbActiveModal,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.getPlatform();
    this.initModalData();
    this.callHandledListener();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getParticipants(room) {
    return room?.participants?.map((userId) =>
      this.store.selectSnapshot(UsersState.getUser)(userId),
    );
  }

  /**
   * Close edit modal handler
   */
  close() {
    this.stopRinging();
    this.activeModal.close();
  }

  callHandledListener(): void {
    this.actions
      .pipe(takeUntil(this.destroy$), ofActionDispatched(VideoCallSetLastSessionId))
      .subscribe(() => this.close());
  }

  stopRinging() {
    this.store.dispatch(new NotificationsSetPlayRingtone({ needPlay: false, ringtone: 'call' }));
  }

  startRinging() {
    this.store.dispatch(new NotificationsSetPlayRingtone({ needPlay: true, ringtone: 'call' }));
  }

  rejectVideoCall() {
    this.store.dispatch(new VideoCallsSendAction({ ...this.room }));
  }

  openVideoCall(): void {
    this.rejectVideoCall();
    this.close();

    if (this.platform !== 'web') {
      this.store.dispatch(new OpenVideoCallMobile(this.room.videoCallId));
    } else {
      const modalRef = this.modalsService.open(VideoCallModalComponent, {
        windowClass: 'cropper-modal video-call-modal',
        backdrop: false,
      });
      modalRef.componentInstance.callData = {
        roomId: this.room.videoCallId,
        user: this.user,
      };
    }
  }

  toggleNoRingtone(isChecked) {
    this.isUpdating = true;
    this.noRingtone = isChecked;

    if (isChecked === true) {
      this.stopRinging();
    } else {
      this.startRinging();
    }

    const body = {
      object: this.object,
      objectId: this.objectId,
      noRingtone: this.noRingtone,
    };
    this.store
      .dispatch(new PushNotificationsSettingsCreate(body))
      .pipe(
        catchError((error) => {
          console.error('Error occurred while dispatching PushNotificationsSettingsCreate:', error);
          this.isUpdating = false;
          return of(null);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((response) => {
        if (response !== null) {
          this.isUpdating = false;
          if (this.noRingtone) {
            this.stopRinging();
          } else {
            this.startRinging();
          }

          this.store.dispatch(new ChatsGet());

          this.toastrService.success(
            this.translocoService.translate('toastr.channel-settings-successfully-updated'),
            this.translocoService.translate('toastr.title-settings'),
          );
        }
      });
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }

  initModalData(): void {
    this.space = null;
    this.project = null;
    this.object = this.room.targetObjectData.object;
    this.objectId = this.room.targetObjectData.objectId;

    if (this.object === 'spaces') {
      this.space = this.store.selectSnapshot(SpacesState.getSpace)(this.objectId);
    } else if (this.object === 'projects') {
      this.project = this.store.selectSnapshot(ProjectsState.getProject)(this.objectId);
    }

    this.participants = this.getParticipants(this.room);

    this.store
      .select(ChatsState.getActiveVideoCallRooms)
      .pipe(takeUntil(this.destroy$))
      .subscribe((rooms) => {
        const currentRoom = rooms?.find((room) => room.videoCallId === this.room.videoCallId);
        if (!currentRoom) {
          this.rejectVideoCall();
        }
        this.participants = this.getParticipants(currentRoom);
      });

    this.startRinging();

    this.cdr.detectChanges();
  }
}
