import { forwardRef, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as Sentry from '@sentry/angular-ivy';
import packageInfo from '../../package.json';

import { ApiInterceptor } from './shared/interceptors/api.interceptor';
import { environment } from '../environments/environment';

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true,
//   wheelPropagation: false,
// };

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true,
};

Sentry.init(
  environment.production
    ? {
        ignoreErrors: [
          'ResizeObserver loop completed with undelivered notifications.',
          'Handled unknown error',
        ],
        dsn: 'https://9f968ee9a6706d18c0c269a3a1643363@o4506750550081536.ingest.sentry.io/4506750639210496',
        integrations: [
          // Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
          Sentry.breadcrumbsIntegration({ console: false }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['api.teamplate.io', 'https://api.teamplate-stage.com'],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        release: packageInfo.version + environment.build_meta,
        environment: environment.sentryEnv,
      }
    : {},
);
