import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

import { RedirectService } from '../services/redirect.service';
import { TenantExists } from '../store/actions/tenants.action';
import { environment } from '../../../environments/environment';

@Injectable()
export class TenantsResolver {
  constructor(
    private router: Router,
    private store: Store,
    private redirectService: RedirectService,
  ) {}
  /**
   * Resolver for checking valid tenant name through api call
   * @param  {ActivatedRouteSnapshot} route
   */
  public resolve(route: ActivatedRouteSnapshot): Promise<string> {
    let tenant: string;
    if (environment.subdomains) {
      tenant = window.location.host.split('.')[0];
      if (tenant === environment.base_host.split('.')[0]) {
        tenant = '';
      }
    } else {
      tenant = route.paramMap.get('tenant');
    }
    return new Promise((resolve, reject) => {
      if (tenant === '') {
        resolve('');
      } else {
        this.store
          .dispatch(
            new TenantExists({
              name: tenant,
            }),
          )
          .pipe(take(1))
          .subscribe(
            (payload) => {
              if (!payload.Tenants.routeValid) {
                if (environment.subdomains) {
                  this.redirectService.go('', 'error404');
                } else {
                  this.router.navigate(['root/error404']);
                }
                reject();
              } else {
                resolve(tenant);
              }
            },
            () => {
              if (environment.subdomains) {
                this.redirectService.go('', 'error404');
              } else {
                this.router.navigate(['root/error404']);
              }
              reject();
            },
          );
      }
    });
  }
}
